import { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import ProtectedRoute from './protectedRoute/ProtectedRoute';
import { useRouterViewModel } from './viewModel/routerViewModel';

const Router = (): JSX.Element => {
  const {
    baseRoute,
    defaultRoute,
    DriverPortfolioPage,
    DriverProfilePage,
    RoutesPage,
    CreateRoutesPage,
  } = useRouterViewModel();

  return (
    <BrowserRouter>
      <Routes>
        <Route path={baseRoute} element={<Navigate to={defaultRoute} />} />
        <Route
          path={DriverPortfolioPage.path}
          element={
            <ProtectedRoute
              element={
                <Suspense fallback={<div>Loading</div>}>
                  <DriverPortfolioPage.Element />
                </Suspense>
              }
              featureToggle={DriverPortfolioPage.featureToggleEnabled}
            />
          }
        />
        <Route
          path={DriverProfilePage.path}
          element={
            <ProtectedRoute
              element={
                <Suspense fallback={<div>Loading</div>}>
                  <DriverProfilePage.Element />
                </Suspense>
              }
              featureToggle={DriverProfilePage.featureToggleEnabled}
            />
          }
        />
        <Route
          path={RoutesPage.path}
          element={
            <ProtectedRoute
              element={
                <Suspense fallback={<div>Loading</div>}>
                  <RoutesPage.Element />
                </Suspense>
              }
              featureToggle={RoutesPage.featureToggleEnabled}
            />
          }
        />
        <Route
          path={CreateRoutesPage.path}
          element={
            <ProtectedRoute
              element={
                <Suspense fallback={<div>Loading</div>}>
                  <CreateRoutesPage.Element />
                </Suspense>
              }
              featureToggle={CreateRoutesPage.featureToggleEnabled}
            />
          }
          errorElement={<Navigate to={RoutesPage.path} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
