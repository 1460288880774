import { RoutePlanningPermissions } from 'deliver-portal-commons';

export const driverPortfolioPermissions = [
  RoutePlanningPermissions.PlanningDriver.Read,
  RoutePlanningPermissions.PlanningDriver.Write,
  RoutePlanningPermissions.PlanningPortfolio.Read,
  RoutePlanningPermissions.PlanningPortfolio.Write,
];

export const routePlanningPermissions = [
  RoutePlanningPermissions.PlanningRoute.Read,
  RoutePlanningPermissions.PlanningRoute.Write,
  RoutePlanningPermissions.PlanningRoute.Delete,
];

export const createRouteDraftPermissions = [
  RoutePlanningPermissions.PlanningRoute.Write,
];
