import { ThemeProvider } from '@hexa-ui/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  EnvConfig,
  EnvProvider,
  ServicesOneProvider,
} from 'deliver-portal-commons';

import { messages } from '../shared/i18n';
import Router from './router/Router';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchInterval: 60000,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

export default function App(envConfig: Readonly<EnvConfig>) {
  return (
    <ThemeProvider theme="force">
      <QueryClientProvider client={queryClient}>
        <EnvProvider envVars={envConfig}>
          <ServicesOneProvider messages={messages}>
            <Router />
          </ServicesOneProvider>
        </EnvProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}
