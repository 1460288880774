import { Navigate } from 'react-router-dom';

import { ProtectedRouteProps } from './ProtectedRoute.types';

export default function ProtectedRoute({
  element,
  featureToggle,
}: Readonly<ProtectedRouteProps>) {
  if (featureToggle === false) {
    return <Navigate to="/error/404" />;
  }
  return element;
}
