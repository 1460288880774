import { ThemeProvider } from '@hexa-ui/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import Router from '../shared/router/Router';

const queryClient = new QueryClient();

export default function App() {
  return (
    <ThemeProvider theme="partner">
      <QueryClientProvider client={queryClient}>
        <Router />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}
