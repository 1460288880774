import en from './languages/en';
import es from './languages/es';
import fr from './languages/fr';
import pt from './languages/pt';

export const messages = {
  [en.language]: en.messages,
  [es.language]: es.messages,
  [pt.language]: pt.messages,
  [fr.language]: fr.messages,
};
