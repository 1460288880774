import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const DriverPortfolioPage = lazy(
  () => import('../../pages/driverPortfolio/DriverPortfolioPage'),
);

const Router = (): JSX.Element => {
  const baseRoute = '/route-planning';

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={`${baseRoute}/driver-portfolio`}
          element={
            <Suspense fallback={<div>Loading</div>}>
              <DriverPortfolioPage />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
